import getters from './getters';
import mutations from './mutations';

const exportRecording = {
  namespaced: true,
  state: {
    beginTime: -1,
    endTime: -1,
    status: 'NORMAL'
  },
  getters,
  mutations,
};

export default exportRecording;
